/*
export function shouldUpdateScroll(prevRouterProps, { location }) {
	
	window.scrollTo(0, 0)
	const body = document.getElementsByTagName('body')[0]
	body.scrollTop = 0
	return false
}*/
exports.shouldUpdateScroll = (prevRouterProps, { location }) => {
	
	/*setTimeout( () => {
		window.scrollTo( 0, 0)
	},100)*/
	return true //Array.from( [0,0])
}